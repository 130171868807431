/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { SetStateAction, useEffect, useState } from 'react'
import {
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableDataProps,
  TableCell,
  Table,
  LucideIcon,
} from '@fileverse/ui'
import { useSortableData } from '../../hooks/useSortableData'
import { getDate } from '../WebPages/WebpagesDraftCard'
import cn from 'classnames'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { useParams } from 'react-router-dom'
import { useContract } from '../../store/contract/hooks'
import { removeContractCollaborator } from '../../store/contract/reducer'
import sendNotifcation from '../../utils/notification'
import { removeCollaboratorCall } from '../../utils/transaction'
import { useAppDispatch } from '../../store/hooks'
import { getAddressName } from '../../utils/getDefaultProfileName'
import { DynamicModal } from '../../pages/PublicPortal/components/DynamicModal'
import { Button } from '../../pages/PublicPortal/components/Button'
import InviteCollaborator from './InviteCollaborator'

export type PeopleData = {
  address: string
  role: 'Owner' | 'Collaborator' | 'Agent' | string
  joinedOn: number
}

export default function PeopleTable({
  peopleData,
  setPeopleData,
  allCollaborators,
  isSafeApp,
}: {
  peopleData: PeopleData[]
  setPeopleData: React.Dispatch<SetStateAction<PeopleData[]>>
  allCollaborators: string[]
  isSafeApp: boolean
}) {
  const [openInvitePopup, setOpenInvitePopup] = useState<boolean>(false)
  const [newCollaboratorAddress, setNewCollaboratorAddress] =
    useState<string>('')

  const customOrder = {
    role: { Owner: 1, Collaborator: 2, Agent: 3 },
  }

  const { data, sortData, sortState } = useSortableData(
    peopleData,
    undefined,
    customOrder
  )

  const onRemoveCollaborator = (collaboratorAddress: string) => {
    setPeopleData((prev) => {
      return prev.filter((item) => item.address !== collaboratorAddress)
    })
  }

  return (
    <>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Address</TableHead>
            <TableHead
              sortOrder={
                sortState.keyName === 'role' ? sortState.order : 'default'
              }
              enableSorting={true}
              onClick={() => sortData('role')}
            >
              Role
            </TableHead>
            <TableHead
              sortOrder={
                sortState.keyName === 'joinedOn' ? sortState.order : 'default'
              }
              enableSorting={true}
              onClick={() => sortData('joinedOn')}
            >
              Joined on
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.map((item, index) => (
            <TableItem
              item={item}
              key={index}
              onRemoveCollaborator={onRemoveCollaborator}
              setOpenInvitePopup={setOpenInvitePopup}
              setNewCollaboratorAddress={setNewCollaboratorAddress}
              allCollaborators={allCollaborators}
            />
          ))}
        </TableBody>
      </Table>
      <InviteCollaborator
        openInvitePopup={openInvitePopup}
        setOpenInvitePopup={setOpenInvitePopup}
        allCollaborators={allCollaborators}
        setPeopleData={setPeopleData}
        triggerCollaboration={true}
        newCollaboratorAddress={newCollaboratorAddress}
        isSafeApp={isSafeApp}
      />
    </>
  )
}

const TableItem = ({
  item,
  onRemoveCollaborator,
  setOpenInvitePopup,
  setNewCollaboratorAddress,
  allCollaborators,
}: {
  item: PeopleData
  onRemoveCollaborator: (collaboratorAddress: string) => void
  setOpenInvitePopup: React.Dispatch<SetStateAction<boolean>>
  setNewCollaboratorAddress: React.Dispatch<SetStateAction<string>>
  allCollaborators: string[]
}) => {
  const { address: contractAddress } = useParams()
  const contract = useContract(contractAddress as string)
  const { walletAddress } = usePrivyHelper()
  const isOwner = walletAddress === contract.owner

  const [isHoverActive, setIsHoverActive] = useState<boolean>(false)
  const [removing, setRemoving] = useState<boolean>(false)
  const [confirmRemoveCollaboratorModal, setConfirmRemoveCollaboratorModal] =
    useState<boolean>(false)

  const dispatch = useAppDispatch()

  const handleRemovedCollaboratorEvent = (collaboratorAddress: string) => {
    dispatch(
      removeContractCollaborator({
        collaboratorAddress: collaboratorAddress,
        contractAddress: contractAddress as string,
      })
    )
    onRemoveCollaborator(collaboratorAddress)
    setRemoving(false)
    setConfirmRemoveCollaboratorModal(false)
    sendNotifcation('Collaborator has been removed successfully', '', 'success')
  }
  const removeCollaborator = async (collaboratorAddress: string) => {
    try {
      setRemoving(true)
      await removeCollaboratorCall({
        contractAddress: contractAddress as string,
        collaboratorAddress,
        previousCollaborator: '0x0000000000000000000000000000000000000001',
        walletAddress: walletAddress as string,
      })

      handleRemovedCollaboratorEvent(collaboratorAddress)
    } catch (error: any) {
      setRemoving(false)
      console.log(error)
      sendNotifcation('Failed to remove collaborator', error.message, 'danger')
    }
  }
  const handleCloseRemoveCollaboratorPopup = (open: boolean) => {
    if (removing) return
    setConfirmRemoveCollaboratorModal(open)
  }

  return (
    <>
      <TableRow
        onMouseEnter={() => setIsHoverActive(true)}
        onMouseLeave={() => setIsHoverActive(false)}
      >
        {(Object.keys(item) as Array<keyof TableDataProps>).map(
          (key, index) => {
            return <TableCells item={item} heading={key} key={index} />
          }
        )}

        {item.role === 'Safe Owner' ? (
          <TableCell>
            {allCollaborators.includes(item.address) ? (
              <LucideIcon name="BadgeCheck" fill="Green" stroke="White" />
            ) : isOwner ? (
              <Button
                size="lg"
                className="min-w-[140px] w-[140px] flex gap-3"
                onClick={() => {
                  setNewCollaboratorAddress(item.address)
                  setOpenInvitePopup(true)
                }}
              >
                Invite
              </Button>
            ) : (
              <p>--</p>
            )}
          </TableCell>
        ) : (
          <TableCell
            className={cn('invisible cursor-pointer', {
              visible:
                isHoverActive &&
                item.role === 'Collaborator' &&
                walletAddress === contract.owner &&
                !removing,
            })}
          >
            <span onClick={() => setConfirmRemoveCollaboratorModal(true)}>
              <LucideIcon name="UserMinus" fill="none" stroke="red" />
            </span>
          </TableCell>
        )}
      </TableRow>
      <DynamicModal
        className="max-w-[600px]"
        open={confirmRemoveCollaboratorModal}
        onOpenChange={handleCloseRemoveCollaboratorPopup}
        title="Remove Collaborator"
        description="Are you sure you want to remove this user as collaborator?"
        primaryAction={{
          label: 'Remove',
          onClick: () => removeCollaborator(item.address),
          isLoading: removing,
          variant: 'danger',
        }}
        secondaryAction={{
          label: 'Cancel',
          onClick: () => setConfirmRemoveCollaboratorModal(false),
        }}
      />
    </>
  )
}

const TableCells = ({
  item,
  heading,
}: {
  item: PeopleData
  heading: string | number
}) => {
  const [ensName, setEnsName] = useState<string>('')

  const getAndSetEnsName = async () => {
    const name = await getAddressName(item.address)
    setEnsName(name)
  }

  useEffect(() => {
    if (heading !== 'address') return
    getAndSetEnsName()
  }, [])

  if (heading === 'address')
    return <TableCell>{ensName || item.address}</TableCell>
  if (heading === 'joinedOn')
    return (
      <TableCell>
        {item.role === 'Safe Owner' || item.role === 'Owner'
          ? '--'
          : getDate(item[heading as keyof PeopleData] as number)}
      </TableCell>
    )
  return <TableCell>{item[heading as keyof PeopleData]}</TableCell>
}
