/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'
import cn from 'classnames'
import { DropzoneInputProps, useDropzone } from 'react-dropzone'
import styles from './index.module.scss'
import { UploadModal } from '../UploadModal'
import { ActivateSignlessModal } from '../ActivateSignlessModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQueueStatus } from '../../../store/taskQueue/hooks'
import { QueueStatus, clearTaskQueue } from '../../../store/taskQueue/reducer'
import { useAppDispatch } from '../../../store/hooks'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { isSignlessEnabled } from '../../../utils/agentHandler'
import { useMediaMax1025px } from '../../../hooks/useMediaQueryHook'

import { Image, Video, Mic, Upload, InfoIcon } from 'lucide-react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Tooltip } from '../../../pages/PublicPortal/components/Tooltip'
import { usePrivyHelper } from '../../../hooks/usePrivyHelper'
import { useContract } from '../../../store/contract/hooks'
// import { Tooltip } from '@fileverse/ui'

interface FileUploadDropZoneProps {
  contentComponent: React.FC<DropZoneContentProps>
  wrapperCss?: string
  pluginConfig?: any
}

const FileUploadDropZone = (props: FileUploadDropZoneProps) => {
  const { wrapperCss, pluginConfig = {} } = props

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [fileArgs, setFileArgs] = useState<any>({})
  const [showUploadModal, toggleUploadModal] = useState(false)
  const [showSignlessModal, toggleSignlessModal] = useState(false)
  const { address: portalAddress = '' } = useParams()
  const walletAddress = usePrivyHelper().walletAddress as string
  const contract = useContract(portalAddress as string)

  const { state: locationState } = useLocation()
  const navigate = useNavigate()
  const queueStatus = useQueueStatus()
  const isDisabled = queueStatus === QueueStatus.Running
  const dispatch = useAppDispatch()

  const onDropOrSelectFiles = useCallback(
    async (files: File[]) => {
      dispatch(clearTaskQueue())
      setSelectedFiles(files)
      if (
        !(await isSignlessEnabled(portalAddress, walletAddress)) &&
        walletAddress === contract.owner
      )
        toggleSignlessModal(true)
      else toggleUploadModal(true)
    },
    [walletAddress]
  )

  const onCloseUploadModal = () => {
    toggleUploadModal(false)
    setSelectedFiles([])
    setFileArgs({})
  }

  const onCompleteSignless = () => {
    toggleSignlessModal(false)
    toggleUploadModal(true)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onDropOrSelectFiles,
    disabled: isDisabled,
  })

  const rootProps = getRootProps()
  const inputProps = getInputProps()

  const readStateFromLocation = useCallback(() => {
    const { file, ...rest } = locationState || {}

    if (!file) return
    setFileArgs(rest)
    onDropOrSelectFiles(file)

    navigate('.', { state: null, replace: true })
  }, [locationState])

  useEffect(() => {
    readStateFromLocation()
  }, [readStateFromLocation])

  const ContentComponent = props.contentComponent

  return (
    <>
      <div
        className={cn(
          'rounded-lg cursor-pointer  upload-zone-tour border-dashed',
          { 'border-[#5C0AFF] border-[2px] ': isDragActive },
          { 'color-border-default border-[1px]': !isDragActive },
          wrapperCss
        )}
        {...rootProps}
      >
        <div
          className={cn(
            'w-full h-full flex flex-col items-center justify-center rounded ',
            { '': isDragActive },
            {
              'opacity-60': isDisabled,
            }
          )}
        >
          <ContentComponent isDisabled={isDisabled} inputProps={inputProps} />
        </div>
      </div>
      {showUploadModal && (
        <UploadModal
          isOpen={showUploadModal}
          onClose={onCloseUploadModal}
          selectedFiles={selectedFiles}
          fileArgs={{
            ...fileArgs,
            ...pluginConfig,
            contractAddress: portalAddress,
          }}
        />
      )}
      {showSignlessModal && (
        <ActivateSignlessModal
          isOpen={showSignlessModal}
          onCompleteSignless={onCompleteSignless}
        />
      )}
    </>
  )
}

interface DropZoneContentProps {
  isDisabled: boolean
  inputProps: DropzoneInputProps
}

export const HomeUploadDropZone = () => {
  const wrapperCss = 'py-[40px]'
  return (
    <div className="w-full flex flex-col gap-[16px]">
      <div className="font-medium text-lg flex gap-[8px] items-center">
        Upload Files
        <Tooltip text="Upload and share any type of multimedia files. All your files availability and security are cryptographically guaranteed.">
          <InfoIcon size="16px" className="text-[#77818A] mb-[-2px]" />
        </Tooltip>
      </div>

      <FileUploadDropZone
        contentComponent={DefaultContent}
        wrapperCss={wrapperCss}
      />
    </div>
  )
}

export const ArweaveUploadZone = () => {
  const isMediaMax1025px = useMediaMax1025px()

  const wrapperCss = cn(
    { ['border-none']: isMediaMax1025px },
    'lg:w-[80%] lg:h-[80%] lg:p-[20px] lg:mb-[0px]'
  )
  const config = {
    isArweave: true,
    maxUploadSizePerFile: 102400, // 100KB
  }
  return (
    <FileUploadDropZone
      contentComponent={ArweaveContent}
      wrapperCss={wrapperCss}
      pluginConfig={config}
    />
  )
}

const DefaultContent = (props: DropZoneContentProps) => {
  const { isDisabled, inputProps } = props
  return (
    <div className={cn(styles.upload_wrapper)}>
      <div className={cn(styles.upload_icon_container)}>
        <input {...inputProps} />
        <Upload size="24px" />
      </div>
      <div className="text-[14px] font-medium text-center ">
        {isDisabled ? (
          <span>Upload in progress</span>
        ) : (
          <>
            <span className="text-[#77818A]">
              Drag and drop files or &nbsp;
            </span>
            <span className="underline">Browse</span>
          </>
        )}
      </div>
    </div>
  )
}

const ArweaveContent = (props: DropZoneContentProps) => {
  const { inputProps } = props
  return (
    <div className={cn(styles.upload_wrapper, 'lg:gap-[30px] content-center')}>
      <ArweaveTopContent />
      <div className={cn(styles.upload_icon_container, styles.arweave_icon)}>
        <input {...inputProps} />
        <UploadFileIcon />
      </div>
      <ArweaveBottomContent />
    </div>
  )
}

const ArweaveTopContent = () => {
  const isMediaMax1025px = useMediaMax1025px()
  if (isMediaMax1025px)
    return (
      <span className="text-center">
        <p className="font-semibold text-lg mb-1">Just upload and share!</p>
        <p>You upload, we encrypt, IPFS stores.</p>
      </span>
    )
  return (
    <span className="text-center">
      <p className="font-semibold text-lg mb-1">Drag & drop files or Browse</p>
      <p>You upload, we encrypt, Arweave stores!</p>
    </span>
  )
}

const ArweaveBottomContent = () => {
  const isMediaMax1025px = useMediaMax1025px()
  return (
    <div
      className={cn('flex gap-[20px]', {
        ['flex-wrap justify-center']: isMediaMax1025px,
      })}
    >
      <FileTypeBadge icon={<Image />} text="Image" />
      <FileTypeBadge icon={<Video />} text="Video" />
      <FileTypeBadge icon={<Mic />} text="Audio" />
      <MoreBadge />
    </div>
  )
}

const MoreBadge = () => {
  const isMediaMax1025px = useMediaMax1025px()
  if (isMediaMax1025px) return null
  return (
    <div className={styles.more_badge}>
      <MoreVertIcon />
    </div>
  )
}

const FileTypeBadge = ({
  icon,
  text,
}: {
  icon: React.ReactNode
  text: string
}) => {
  return (
    <div className={styles.file_type_badge}>
      {icon}
      <span>{text}</span>
    </div>
  )
}
