/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { getPenumbra } from './files/getPenumbra'

export const getIPFSAsset = async ({
  ipfsHash,
  options,
  lastIPFSUrl,
}: {
  ipfsHash: string
  lastIPFSUrl?: string
  options?: AxiosRequestConfig<any> | undefined
}) => {
  if (lastIPFSUrl) {
    try {
      const encodedURI = encodeURI(lastIPFSUrl)
      const result = await axios.get(encodedURI, {
        ...options,
      })
      return { ...result, url: lastIPFSUrl }
    } catch (error) {
      console.log('Failed to fetch from cache url, trying other gateways')
      console.log(error)
    }
  }
  const gatewayUrls = process.env.REACT_APP_IPFS_GATEWAYS?.split(', ')
  if (!gatewayUrls) return

  const controller = new AbortController()
  const requests: Promise<AxiosResponse<any, any>>[] = []

  gatewayUrls?.forEach((url) => {
    const uri = `${url}/ipfs/${ipfsHash}`
    const encodedURI = encodeURI(uri)
    requests.push(
      axios
        .get(encodedURI, {
          signal: controller.signal,
          ...options,
        })
        .then((response) => {
          // Abort the other requests
          if (response.status === 200) {
            controller.abort()

            return {
              url: uri,
              ...response,
            }
          }
          throw new Error('Failed with status code ' + response?.status)
        })
        .catch((error) => {
          if (error?.code !== 'ERR_CANCELED') {
            console.error(`Failed to fetch from ${uri}`, error)
          }
          throw error
        })
    )
  })
  const data = await Promise.any(requests)

  return data
}

export const getDecryptedIPFSAsset = async ({
  ipfsHash,
  decryptionInfo,
  ipfsUrl,
}: {
  ipfsHash: string
  ipfsUrl?: string
  decryptionInfo: any
  options?: AxiosRequestConfig<any> | undefined
}): Promise<{ data: any; ipfsUrl: string } | undefined> => {
  const penumbra = await getPenumbra()
  if (ipfsUrl) {
    try {
      const data: any = await penumbra.get({
        url: ipfsUrl,
        decryptionOptions: decryptionInfo,
      })
      return { data, ipfsUrl: data[0].url }
    } catch (error) {
      console.log(error)
    }
  }
  const gatewayUrls = process.env.REACT_APP_IPFS_GATEWAYS?.split(', ')
  if (!gatewayUrls) return
  const requests: Promise<any>[] = []
  gatewayUrls?.forEach((url) => {
    requests.push(
      penumbra.get({
        url: `${url}/ipfs/${ipfsHash}`,
        decryptionOptions: decryptionInfo,
      })
    )
  })
  const data = await Promise.any(requests)

  return { data, ipfsUrl: data[0].url }
}

export const getCoverFromIPFS = async (contentIPFSHash: string) => {
  const image = await getIPFSAsset({
    ipfsHash: contentIPFSHash,
    options: { responseType: 'blob' },
  })
  const imageURL = URL.createObjectURL(image?.data)
  return imageURL
}
