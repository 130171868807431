/* eslint-disable @typescript-eslint/no-explicit-any */
import { AllowedChainId } from '../../config/wagmi-config'
import { defaultNetwork } from '../../config/network-config'
import { RelayerError } from '../errors'
import { Contract, JsonRpcSigner } from 'ethers'
import {
  type AddCollaboratorArgs,
  type AddFileTransactionArgs,
  type DeleteFileArgs,
  type EditFileTransactionArgs,
  type MintPortalTrxArgs,
  type RemoveCollaboratorArgs,
  type SignerOrWallet,
  type TransactionArgs,
  ContractType,
  ContractMethod,
  PortalContractEventEnum,
  PortalMetadataArgs,
} from './interface'
import { getPortalContract, getRegistryContract } from '../contract'
import { NEW_PORTAL_DEFAULT_METADATA_IPFS_HASH } from '../../constants'
import { handleSafeAppTransaction, isSafeApp } from '../safeApp'
import { getEthersSigner } from '../ethUtils'
import { sendMetaTx } from '../metaTransaction/metaTx'
import {
  onBeforeTrx,
  parseTrxEventLogs,
  waitForTransactionReceipt,
} from './trxUtils'
import { getAgentWallet } from '../agentHandler'
import { DELETE_FILE_METADATA } from './constant'

export const handleMetaTrx = async (
  trxArgs: TransactionArgs,
  signerOrWallet: SignerOrWallet
) => {
  const response = await sendMetaTx(
    trxArgs.walletAddress,
    trxArgs.contract,
    trxArgs.encodedFunction,
    signerOrWallet
  )

  if (!response || response?.status !== 'success')
    throw new RelayerError('Transaction sent to the relayer was not successful')

  return JSON.parse(response?.result).response.hash
}

export const handleSignlessTransaction = async (
  portalContract: Contract,
  walletAddress: string,
  functionToExecute: string
) => {
  const { target: portalAddress } = portalContract
  const agentWallet = await getAgentWallet(
    walletAddress,
    portalAddress.toString()
  )

  const { address: agentAddress } = agentWallet

  return await handleMetaTrx(
    {
      walletAddress: agentAddress,
      contract: portalContract,
      encodedFunction: functionToExecute,
    },
    agentWallet
  )
}

export const handleTransaction = async (trxArgs: TransactionArgs) => {
  if (await isSafeApp())
    return await handleSafeAppTransaction(
      trxArgs.encodedFunction,
      trxArgs.contract.target.toString()
    )

  const signer: JsonRpcSigner = await getEthersSigner({
    chainId: defaultNetwork.chainId as AllowedChainId,
  })

  return await handleMetaTrx(trxArgs, signer)
}

const getEncodedContractFunction = (
  contract: Contract,
  method: string,
  args: any
) => contract.interface.encodeFunctionData(method, args)

/* Base Trx Functions */
// base function to mint a new portal
export const mintPortalTransaction =
  // onBeforeTrx(
  async (mintPortalArgs: MintPortalTrxArgs) => {
    const { walletAddress, secretFileContent, keyVerifiers } = mintPortalArgs
    const registryContract = getRegistryContract()
    const encodedFunction = getEncodedContractFunction(
      registryContract,
      ContractMethod.Mint,
      [
        NEW_PORTAL_DEFAULT_METADATA_IPFS_HASH,
        secretFileContent.viewDID,
        secretFileContent.editDID,
        keyVerifiers.portalEncryptionKeyVerifier.valueOf(),
        keyVerifiers.portalDecryptionKeyVerifier.valueOf(),
        keyVerifiers.memberEncryptionKeyVerifer.valueOf(),
        keyVerifiers.memberDecryptionKeyVerifer.valueOf(),
      ]
    )

    return await handleTransaction({
      walletAddress,
      contract: registryContract,
      encodedFunction,
    })
  }
// )

// base function to add a collaborator
export const addCollaboratorTrx = onBeforeTrx(
  async (
    walletAddress: string,
    portalContractAddress: string,
    collaboratorAddress: string
  ) => {
    const portalContract = getPortalContract(portalContractAddress)
    const encodedFunction = getEncodedContractFunction(
      portalContract,
      ContractMethod.AddCollaborator,
      [collaboratorAddress]
    )

    return await handleTransaction({
      walletAddress,
      contract: portalContract,
      encodedFunction,
    })
  }
)

// base function to register collaborator keys
export const registerCollaboratorTrx = onBeforeTrx(
  async (
    portalContractAddress: string,
    walletAddress: string,
    collaboratorKeys: {
      viewDID: string
      editDID: string
    }
  ) => {
    const portalContract = getPortalContract(portalContractAddress)
    const encodedFunction = getEncodedContractFunction(
      portalContract,
      ContractMethod.RegisterCollaboratorKeys,
      [collaboratorKeys.viewDID, collaboratorKeys.editDID]
    )

    return await handleTransaction({
      walletAddress,
      contract: portalContract,
      encodedFunction,
    })
  }
)

export const removeCollaboratorKeysTrx = onBeforeTrx(
  async (portalAddress: string, walletAddress: string) => {
    const portalContract = getPortalContract(portalAddress)
    const encodedFunction = getEncodedContractFunction(
      portalContract,
      ContractMethod.RemoveCollaboratorKeys,
      null
    )
    return await handleTransaction({
      walletAddress,
      contract: portalContract,
      encodedFunction,
    })
  }
)

// base function to add a file
const addFileTransaction = onBeforeTrx(
  async (addFileTrxArgs: AddFileTransactionArgs, isSignless = false) => {
    const portalContract = getPortalContract(addFileTrxArgs.contractAddress)
    const encodedFunction = getEncodedContractFunction(
      portalContract,
      ContractMethod.AddFile,
      [
        addFileTrxArgs.metadataIpfsHash,
        addFileTrxArgs.contentIpfsHash,
        addFileTrxArgs.gateIpfsHash,
        addFileTrxArgs.fileType,
        addFileTrxArgs.version || 0,
      ]
    )

    if (isSignless)
      return await handleSignlessTransaction(
        portalContract,
        addFileTrxArgs.walletAddress,
        encodedFunction
      )

    return await handleTransaction({
      walletAddress: addFileTrxArgs.walletAddress,
      contract: portalContract,
      encodedFunction,
    })
  }
)

// base function to edit a file
export const editFileTransaction = onBeforeTrx(
  async (editFileTrxArgs: EditFileTransactionArgs, isSignless = false) => {
    const portalContract = getPortalContract(editFileTrxArgs.contractAddress)
    const encodedFunction = getEncodedContractFunction(
      portalContract,
      ContractMethod.EditFile,
      [
        editFileTrxArgs.fileId,
        editFileTrxArgs.metadataIpfsHash,
        editFileTrxArgs.contentIpfsHash,
        editFileTrxArgs.gateIpfsHash,
        editFileTrxArgs.fileType,
        editFileTrxArgs.version || 0,
      ]
    )
    if (isSignless)
      return await handleSignlessTransaction(
        portalContract,
        editFileTrxArgs.walletAddress,
        encodedFunction
      )

    return await handleTransaction({
      walletAddress: editFileTrxArgs.walletAddress,
      contract: portalContract,
      encodedFunction,
    })
  }
)

export const updatePortalMetadataTrx = onBeforeTrx(
  async (updatePortalMetadataArgs: PortalMetadataArgs) => {
    const portalContract = getPortalContract(
      updatePortalMetadataArgs.contractAddress
    )
    const encodedFunction = getEncodedContractFunction(
      portalContract,
      ContractMethod.UpdateMetadata,
      [updatePortalMetadataArgs.metadataIpfsHash]
    )

    return await handleTransaction({
      walletAddress: updatePortalMetadataArgs.walletAddress,
      contract: portalContract,
      encodedFunction,
    })
  }
)
/* End Base Trx Functions */

export const addCollaboratorCall = async (
  addCollboratorArgs: AddCollaboratorArgs
) => {
  const { walletAddress, contractAddress, collaboratorAddress } =
    addCollboratorArgs

  const txHash = await addCollaboratorTrx(
    walletAddress,
    contractAddress,
    collaboratorAddress
  )

  const txReceipt = await waitForTransactionReceipt(txHash)

  const parsedEvent = parseTrxEventLogs(
    txReceipt.logs,
    ContractType.Portal,
    PortalContractEventEnum.ADDED_COLLABORATOR
  )

  return {
    txHash,
    txReceipt,
    parsedEvent,
  }
}

export const addFileTrxCall = async (
  addFileTrxArgs: AddFileTransactionArgs,
  isSignless = false
) => {
  const trxHash = await addFileTransaction(addFileTrxArgs, isSignless)
  const { logs } = await waitForTransactionReceipt(trxHash)
  const parsedLogs = parseTrxEventLogs(
    logs,
    ContractType.Portal,
    PortalContractEventEnum.ADDED_FILE
  )

  return parsedLogs.fileId.toString()
}

export const editFileTrxCall = async (
  editFileTrxArgs: EditFileTransactionArgs,
  isSignless = false
) => {
  const trxHash = await editFileTransaction(editFileTrxArgs, isSignless)
  const { logs } = await waitForTransactionReceipt(trxHash, 2000)
  const parsedLogs = parseTrxEventLogs(
    logs,
    ContractType.Portal,
    PortalContractEventEnum.EDITED_FILE
  )

  return Number(parsedLogs.fileId)
}

export const deleteFileTrxCall = async (
  deleteFileArgs: DeleteFileArgs,
  isSignless = false
) => {
  return await editFileTrxCall(
    {
      contractAddress: deleteFileArgs.contractAddress,
      fileId: deleteFileArgs.fileId,
      walletAddress: deleteFileArgs.walletAddress,
      version: DELETE_FILE_METADATA.version,
      fileType: DELETE_FILE_METADATA.fileType,
      metadataIpfsHash: DELETE_FILE_METADATA.metadataIpfsHash,
      contentIpfsHash: DELETE_FILE_METADATA.contentIpfsHash,
      gateIpfsHash: DELETE_FILE_METADATA.gateIpfsHash,
    },
    isSignless
  )
}

export const removeCollaboratorTrx = onBeforeTrx(
  async (removeCollaboratorArgs: RemoveCollaboratorArgs) => {
    const portalContract = getPortalContract(
      removeCollaboratorArgs.contractAddress
    )
    const encodedFunction = getEncodedContractFunction(
      portalContract,
      ContractMethod.RemoveCollaborator,
      [
        removeCollaboratorArgs.previousCollaborator,
        removeCollaboratorArgs.collaboratorAddress,
      ]
    )

    return await handleTransaction({
      walletAddress: removeCollaboratorArgs.walletAddress,
      contract: portalContract,
      encodedFunction,
    })
  }
)

export const removeCollaboratorCall = async (
  removeCollaboratorArgs: RemoveCollaboratorArgs
) => {
  const txHash = await removeCollaboratorTrx(removeCollaboratorArgs)

  const txReceipt = await waitForTransactionReceipt(txHash)

  const parsedEvent = parseTrxEventLogs(
    txReceipt.logs,
    ContractType.Portal,
    PortalContractEventEnum.REMOVED_COLLABORATOR
  )

  return {
    txHash,
    txReceipt,
    parsedEvent,
  }
}

export const updatePortalMetadataCall = async (args: PortalMetadataArgs) => {
  const txHash = await updatePortalMetadataTrx(args)

  const txReceipt = await waitForTransactionReceipt(txHash)

  const parsedEvent = parseTrxEventLogs(
    txReceipt.logs,
    ContractType.Portal,
    PortalContractEventEnum.UPDATED_PORTAL_METADATA
  )

  return {
    txHash,
    txReceipt,
    parsedEvent,
  }
}
