/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react'

import { useParams, useSearchParams } from 'react-router-dom'
import {
  usePortalSignlessMode,
  usePublicLayoutFileId,
} from '../../store/contract/hooks'
import {
  ISectionsProps,
  LinkFileMetadata,
  PublicPortalLayout,
  Socials,
} from '../../pages/PublicPortal/interfaces'

import { v4 as uuidv4 } from 'uuid'
import { CardSize, FileType, LayoutModes } from '@fileverse/ui'
import { INewFile } from '../../types/interface/file.interface'

import { getFileTypeForCard } from '../../utils/fileUtils'
import { UniqueIdentifier } from '@dnd-kit/core'
import {
  createAndUploadPublicPortalFile,
  updatePublicPortalFileCall,
  BASE_PUBLIC_PORTAL_FILE as INITIAL_STATE,
} from '../../utils/publicPortalUtils'
import sendNotifcation, { clearAllNotification } from '../../utils/notification'

import { useAppDispatch } from '../../store/hooks'
import { cloneDeep } from 'lodash'
import {
  fetchPublicPortalData,
  updateContractLayoutFileIdThunk,
} from '../../store/contract/thunks'
import {
  useInvokerCredential,
  useInvokerStatus,
} from '../../store/invoker/hooks'
import { InvokerStatus } from '../../types'
import { isValidUrl } from '../../pages/PublicPortal/utils/isValidUrl'
import { getLinkMetadata } from '../../api/linkMetadata/getLinkMetadata'
import { indexPortal } from '../../api/portal'
import { SetCommentMetdataReturnType } from '../../components/WebPages/WebPageCommentSection'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'
import { captureException } from '@sentry/react'

interface PublicPortalProviderProps {
  children: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IPublicPortalContext extends PublicPortalLayout {
  addSection: () => void
  isViewMode: boolean
  onSectionTitleChange: (position: number, title: string) => void
  onSectionLayoutChange: (position: number, layout: LayoutModes) => void
  onRemoveSection: (position: number) => void
  onRemoveFile: (id: UniqueIdentifier) => void
  setSections: React.Dispatch<React.SetStateAction<ISectionsProps[]>>
  addTextFile: (position: number) => void
  onUpdateTextFileContent: (fileId: string, newTextContent: string) => void
  onAddFiles: (files: any[]) => void
  onSetActiveSection: (position: number) => void
  addLinkFile: (userLink: string, callbackFunc: () => void) => void
  fetchingMetadata: boolean
  loading: boolean
  loadingButton: boolean
  onSaveLayout: () => Promise<void>
  layoutSaved: boolean
  updateName: (portalName: string) => void
  updateDescription: (portalDescription: string) => void
  updateAvatarIPFSUrl: (portalAvatarIPFSUrl: string) => void
  updateCoverIPFSUrl: (portalCoverIPFSUrl: string) => void
  showConfirmModal: boolean
  setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>
  showAddFilesModal: boolean
  setShowAddFilesModal: React.Dispatch<React.SetStateAction<boolean>>
  updateLinkFileMetadata: (
    metadata: LinkFileMetadata,
    fileId: UniqueIdentifier
  ) => void
  layoutSwitchEnabled: boolean
  commentsEnabled: boolean
  setLayoutSwitchEnabled: (enabled: boolean) => void
  setCommentsEnabled: (enabled: boolean) => void
  activeSectionIdx: number
  setActiveSectionIdx: React.Dispatch<React.SetStateAction<number>>
  setCommentsMetadata: (data: SetCommentMetdataReturnType) => void
  commentsMetadata: SetCommentMetdataReturnType
}

const PublicPortalContext = React.createContext<IPublicPortalContext | null>(
  null
)

export const usePublicPortalContext = () => {
  const context = React.useContext(PublicPortalContext)
  if (!context) {
    throw new Error(
      'usePublicPortalContext must be used within a PublicPortalProvider'
    )
  }
  return context
}

export const PublicPortalProvider = (props: PublicPortalProviderProps) => {
  const [searchParams] = useSearchParams()
  const chainId = parseInt(searchParams.get('chainId') || '')
  const { address: contractAddress } = useParams()

  const address = usePrivyHelper().walletAddress as string
  const publicLayoutFileId = usePublicLayoutFileId(
    (contractAddress as string) || ''
  )
  const dispatch = useAppDispatch()

  const isSignless = usePortalSignlessMode(contractAddress as string)

  const invokerStatus = useInvokerStatus(
    address as string,
    contractAddress as string
  )

  const [publicPortalLayout, setPublicPortalLayout] =
    useState<PublicPortalLayout>({
      ...INITIAL_STATE,
      sections: [...INITIAL_STATE.sections],
      socials: { ...INITIAL_STATE.socials },
    })

  const [activeSectionIdx, setActiveSectionIdx] = useState<number>(0)

  const [sections, setSections] = useState<ISectionsProps[]>([])

  const [loading, setLoading] = useState(true)
  // Loading for Button
  const [loadingButton, setLoadingButton] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [showAddFilesModal, setShowAddFilesModal] = useState<boolean>(false)
  const [layoutSaved, setLayoutSaved] = useState<boolean>(false)
  const isLoading = usePrivyHelper().isLoading
  const [isLoaded, setLoaded] = useState<boolean>(false)

  const [fetchingMetadata, setFetchingMetadata] = useState<boolean>(false)
  const [commentsMetadata, setCommentsMetadata] =
    useState<SetCommentMetdataReturnType>({ available: true, count: 0 })

  const credentials = useInvokerCredential(
    address as string,
    contractAddress as string
  )
  const setupPublicPortal = useCallback(async () => {
    if (!contractAddress || isLoading || isLoaded) return
    try {
      setLoading(true)
      const layoutData = await dispatch(
        fetchPublicPortalData({
          contractAddress: contractAddress as string,
          address: address as string,
          chainId,
        })
      ).unwrap()

      setPublicPortalLayout(layoutData || cloneDeep(INITIAL_STATE))
      const sections = layoutData?.sections?.length
        ? layoutData.sections
        : [
            {
              id: `section-${uuidv4()}`,
              title: 'New Section',
              files: [],
              layout: LayoutModes.CARD,
              position: 0,
            },
          ]
      setSections(sections)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
      setLoaded(true)
    }
  }, [address, chainId, contractAddress, dispatch, isLoading, isLoaded])

  useEffect(() => {
    setupPublicPortal()
  }, [setupPublicPortal])

  const addSection = () => {
    setSections((prev) => [
      ...prev,
      {
        id: `section-${uuidv4()}`,
        title: 'New Section',
        files: [],
        layout: LayoutModes.CARD,
        position: prev.length ? prev[prev.length - 1].position + 1 : 0,
      },
    ])
    setActiveSectionIdx(sections.length)
  }

  const onAddFiles = (files: INewFile[]) => {
    const preparedFiles = files.map((file) => {
      return {
        id: `item-${uuidv4()}`,
        size: 'sm' as CardSize,
        type: getFileTypeForCard(file),
        metadata: {
          fileId: file.fileId,
          ipfsHash: file.contentIPFSHash,
          ogImageUrl: '',
          name: file.metadata.name,
          mimeType: file.metadata.mimeType,
          extension: file.metadata.extension,
        },
        likeCount: 0,
        liked: false,
      }
    })

    setSections((prev) => {
      if (!prev.length) {
        return [
          {
            id: `section-${uuidv4()}`,
            title: 'New Section',
            files: preparedFiles,
            layout: LayoutModes.CARD,
            position: 0,
          },
        ]
      }
      const updatedSections = [...prev]
      updatedSections[activeSectionIdx].files = [
        ...updatedSections[activeSectionIdx].files,
        ...preparedFiles,
      ]
      return updatedSections
    })
  }

  const onSectionTitleChange = (position: number, title: string) => {
    setSections((prev) => {
      const updatedSections = [...prev]
      updatedSections[position].title = title
      return updatedSections
    })
  }

  const onSectionLayoutChange = (position: number, layout: LayoutModes) => {
    setSections((prev) => {
      const updatedSections = [...prev]
      updatedSections[position].layout = layout
      return updatedSections
    })
  }

  const onRemoveSection = (position: number) => {
    setSections((prev) => {
      const updatedSections = [...prev]
      updatedSections.splice(position, 1)
      return updatedSections
    })
  }

  const onRemoveFile = (id: UniqueIdentifier) => {
    setSections((prev) => {
      const updatedSections = [...prev]
      const sectionIndex = updatedSections.findIndex((section) =>
        section.files.some((file) => file.id === id)
      )
      updatedSections[sectionIndex].files = updatedSections[
        sectionIndex
      ].files.filter((file) => file.id !== id)
      return updatedSections
    })
  }

  const addTextFile = (position: number) => {
    setSections((prev) => {
      if (!prev.length) {
        return [
          {
            id: `section-${uuidv4()}`,
            title: 'New Section',
            files: [
              {
                id: `item-${uuidv4()}`,
                size: 'sm' as CardSize,
                type: 'note' as FileType,
                metadata: {
                  textContent: '',
                  uploadedAt: new Date().getTime(),
                },
                likeCount: 0,
                liked: false,
              },
            ],
            layout: LayoutModes.CARD,
            position: 0,
          },
        ]
      }

      const updatedSections = [...prev]
      updatedSections[position].files = [
        ...updatedSections[position].files,
        {
          id: `item-${uuidv4()}`,
          size: 'sm' as CardSize,
          type: 'note' as FileType,
          metadata: {
            textContent: '',
            uploadedAt: new Date().getTime(),
          },
          likeCount: 0,
          liked: false,
        },
      ]
      return updatedSections
    })
  }

  const onUpdateTextFileContent = (fileId: string, newTextContent: string) => {
    setSections((prev) => {
      const updatedSections = [...prev]

      updatedSections.forEach((section) => {
        const fileIndex = section.files.findIndex((file) => file.id === fileId)

        if (fileIndex !== -1) {
          section.files[fileIndex] = {
            ...section.files[fileIndex],
            metadata: {
              ...section.files[fileIndex].metadata,
              textContent: newTextContent,
            },
          }
        }
      })

      return updatedSections
    })
  }

  const fetchMetadata = async (userLink: string) => {
    try {
      const response = await getLinkMetadata(userLink)

      if (
        response?.data['og:url'] === '' &&
        !response?.data?.data.urlType.includes('warpcast')
      ) {
        sendNotifcation('Failed to fetch metadata', '', 'danger')
        setFetchingMetadata(false)
        return
      }
      return response
    } catch (error) {
      console.error('Failed to fetch metadata:', error)
      sendNotifcation('Failed to fetch metadata of given link', '', 'danger')
      setFetchingMetadata(false)
    }
  }

  const addLinkFile = async (userLink: string, callbackFunc: () => void) => {
    setFetchingMetadata(true)
    if (!isValidUrl(userLink)) {
      sendNotifcation('Invalid URL provided.', '', 'danger')
      setFetchingMetadata(false)
      return
    }

    const response = await fetchMetadata(userLink)
    if (!response) return
    callbackFunc && callbackFunc()

    setSections((prev) => {
      if (!prev.length) {
        return [
          {
            id: `section-${uuidv4()}`,
            title: 'New Section',
            files: [
              {
                id: `item-${uuidv4()}`,
                size: 'sm' as CardSize,
                type: 'link' as FileType,
                metadata: {
                  link: userLink,
                  title: '',
                  imageUrl: '',
                  faviconUrl: '',
                  source: '',
                },
                likeCount: 0,
                liked: false,
              },
            ],
            layout: LayoutModes.CARD,
            position: 0,
          },
        ]
      }
      if (response.data.urlType === 'warpcast::profile') {
        const updatedSections = [...prev]
        updatedSections[activeSectionIdx].files = [
          ...updatedSections[activeSectionIdx].files,
          {
            id: `item-${uuidv4()}`,
            size: 'sm' as CardSize,
            type: 'link' as FileType,
            metadata: {
              title: response?.data?.data?.username,
              source: 'warpcast',
              imageUrl: response?.data?.data?.pfp?.url,
              faviconUrl: 'https://warpcast.com/favicon.png',
              link: userLink,
            },
            likeCount: 0,
            liked: false,
          },
        ]
        return updatedSections
      }
      if (response.data.urlType === 'warpcast::cast') {
        const updatedSections = [...prev]
        updatedSections[activeSectionIdx].files = [
          ...updatedSections[activeSectionIdx].files,
          {
            id: `item-${uuidv4()}`,
            size: 'sm' as CardSize,
            type: 'link' as FileType,
            metadata: {
              title: response?.data?.data?.text,
              source: 'warpcast',
              imageUrl: response?.data?.data?.embeds[0]?.url,
              faviconUrl: 'https://warpcast.com/favicon.png',
              link: userLink,
            },
            likeCount: 0,
            liked: false,
          },
        ]
        return updatedSections
      }
      if (response.data.urlType === 'warpcast::channel') {
        const updatedSections = [...prev]
        updatedSections[activeSectionIdx].files = [
          ...updatedSections[activeSectionIdx].files,
          {
            id: `item-${uuidv4()}`,
            size: 'sm' as CardSize,
            type: 'link' as FileType,
            metadata: {
              title: response?.data?.data?.description,
              source: 'warpcast',
              imageUrl: response?.data?.data?.image_url,
              faviconUrl: 'https://warpcast.com/favicon.png',
              link: response?.data?.url,
            },
            likeCount: 0,
            liked: false,
          },
        ]
        return updatedSections
      }
      const updatedSections = [...prev]
      updatedSections[activeSectionIdx].files = [
        ...updatedSections[activeSectionIdx].files,
        {
          id: `item-${uuidv4()}`,
          size: 'sm' as CardSize,
          type: 'link' as FileType,
          metadata: {
            title: response?.data?.data['og:title'],
            source: response?.data?.data['og:site_name'],
            imageUrl: response?.data?.data['og:image'],
            faviconUrl: response?.data?.data?.favicons[0]?.href,
            link: userLink,
          },
          likeCount: 0,
          liked: false,
        },
      ]
      return updatedSections
    })
    setFetchingMetadata(false)
  }

  const updateLinkFileMetadata = async (
    metadata: LinkFileMetadata,
    fileId: UniqueIdentifier
  ) => {
    setSections((prev) => {
      const updatedSections = [...prev]
      const sectionIndex = activeSectionIdx

      const fileIndex = updatedSections[sectionIndex].files.findIndex(
        (file) => file.id === fileId
      )

      if (fileIndex !== -1) {
        updatedSections[sectionIndex].files[fileIndex] = {
          ...updatedSections[sectionIndex].files[fileIndex],
          metadata: {
            ...updatedSections[sectionIndex].files[fileIndex].metadata,
            ...metadata,
          },
        }
      }
      return updatedSections
    })
  }

  const onSetActiveSection = (position: number) => setActiveSectionIdx(position)

  const onSaveLayout = async () => {
    try {
      setLoadingButton(true)
      sendNotifcation(
        'Saving Changes',
        'Please wait while we save your changes.',
        'info',
        200000
      )
      const updatedJSON = { ...publicPortalLayout, sections }
      let layoutFileId = publicLayoutFileId
      if (publicLayoutFileId)
        await updatePublicPortalFileCall({
          address: address as string,
          contractAddress: contractAddress as string,
          publicPortalData: updatedJSON,
          fileId: publicLayoutFileId as string,
          isSignless,
        })
      else {
        const { fileId } = await createAndUploadPublicPortalFile({
          address: address as string,
          contractAddress: contractAddress as string,
          isSignless,
          publicPortalData: updatedJSON,
        })
        layoutFileId = fileId

        await dispatch(
          updateContractLayoutFileIdThunk({
            contractAddress: contractAddress as string,
            layoutFileId: fileId,
          })
        )
      }
      indexPortal({
        contractAddress: contractAddress as string,
        invokerAddress: address as string,
        publicLayoutFileId: layoutFileId as string,
        chain: chainId,
        editSecret: credentials?.editSecret,
      }).catch((err) => {
        console.error(err)
      })
      clearAllNotification()
      sendNotifcation(
        'Changes Saved',
        'Your changes have been saved successfully.',
        'success',
        5000
      )
      setLayoutSaved(true)
    } catch (err) {
      console.log(err)
      clearAllNotification()
      captureException(err)
      sendNotifcation(
        'Error',
        'An error occurred while saving your changes.',
        'danger',
        5000
      )
      setLayoutSaved(false)
    } finally {
      setLoadingButton(false)
    }
  }

  const updateName = (portalName: string) => {
    setPublicPortalLayout({ ...publicPortalLayout, name: portalName })
  }

  const updateDescription = (portalDescription: string) => {
    setPublicPortalLayout({
      ...publicPortalLayout,
      description: portalDescription,
    })
  }

  const updateAvatarIPFSUrl = (portalAvatarIPFSUrl: string) => {
    setPublicPortalLayout({
      ...publicPortalLayout,
      avatarIPFSUrl: portalAvatarIPFSUrl,
    })
  }

  const updateCoverIPFSUrl = (portalCoverIPFSUrl: string) => {
    setPublicPortalLayout({
      ...publicPortalLayout,
      coverIPFSUrl: portalCoverIPFSUrl,
    })
  }

  const setLayoutSwitchEnabled = (enabled: boolean) => {
    setPublicPortalLayout({
      ...publicPortalLayout,
      layoutSwitchEnabled: !enabled,
    })
  }

  const setCommentsEnabled = (enabled: boolean) => {
    setPublicPortalLayout({
      ...publicPortalLayout,
      commentsEnabled: !enabled,
    })
  }

  const {
    coverIPFSUrl = '',
    avatarIPFSUrl = '',
    description = '',
    name = '',
    socials = {},
    layoutSwitchEnabled = true,
    commentsEnabled = true,
  } = publicPortalLayout || {}

  const contextVal = {
    coverIPFSUrl,
    avatarIPFSUrl,
    description,
    name,
    socials: socials as Socials,
    sections,
    isViewMode: invokerStatus === InvokerStatus.Visitor,
    loading,
    loadingButton,
    publicLayoutFileId,
    layoutSaved,
    showConfirmModal,
    layoutSwitchEnabled,
    commentsEnabled,
    fetchingMetadata,
    activeSectionIdx,
    commentsMetadata,
  }

  return (
    <PublicPortalContext.Provider
      value={{
        ...contextVal,
        updateName,
        addSection,
        onAddFiles,
        addTextFile,
        onUpdateTextFileContent,
        addLinkFile,
        setSections,
        onRemoveFile,
        onSaveLayout,
        onRemoveSection,
        updateDescription,
        updateCoverIPFSUrl,
        onSetActiveSection,
        updateAvatarIPFSUrl,
        onSectionTitleChange,
        onSectionLayoutChange,
        setShowConfirmModal,
        setShowAddFilesModal,
        showAddFilesModal,
        updateLinkFileMetadata,
        setLayoutSwitchEnabled,
        setCommentsEnabled,
        setActiveSectionIdx,
        setCommentsMetadata,
      }}
    >
      {props.children}
    </PublicPortalContext.Provider>
  )
}
