/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, createContext, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { NotifcationQueryParams } from './NotificationCenter'
import {
  getNotification,
  getTotalUnreadNotifications,
} from '../../api/notification/getNotification'
import { useInvokerCredential } from '../../store/invoker/hooks'
import Logo from '../../assets/mainLogo.svg'
import {
  useMutation,
  useInfiniteQuery,
  useQueryClient,
  useQuery,
  InfiniteData,
  UseMutateFunction,
} from '@tanstack/react-query'
import { NotificationType } from '../../types/enum/notification'
import {
  DEFAULT_TIMESTAMP,
  FETCH_TOTAL_UNREAD_NOTIFICATION_KEY,
  NOTIFICATION_QUERY_KEY,
} from '../../constants'
import { processNotification as processNotificationApi } from '../../api/notification/processNotification'
import { FileTypeEnum } from '../../types/enum/file.enum'
import markAllNotificationsAsRead from '../../api/notification/markAllNotificationsAsRead'
import rejectPortalInviteAPI from '../../api/notification/rejectPortalInviteAPI'
import { AxiosResponse } from 'axios'
import { usePrivyHelper } from '../../hooks/usePrivyHelper'

export interface INotification {
  _id: string
  action: boolean
  portalAddress: string
  forAddress: string[]
  audience: string
  portalLogo: string
  context: {
    by: string
    link: string
    metadataIPFSHash: string
    fileType: FileTypeEnum
    fileMetadata: {
      name: string
      mimeType: string
      owner: string
    }
    fileId: string
    text: string
  }
  message: string
  type: NotificationType
  blockTimestamp: number
  processed: boolean
  timeStamp: number
  __v: number
}
interface ISelectedPortal {
  name: string
  address: string
}
export interface INotificationState {
  notifications: INotification[]
  isNotificationsLoading: boolean
  isNotificationError: boolean
  isFetchingNotification: boolean
  setQueryParams: Dispatch<SetStateAction<NotifcationQueryParams>>
  selectedPortal: ISelectedPortal
  setSelectedPortal: Dispatch<SetStateAction<ISelectedPortal>>
  fetchNextPage: () => any
  queryParams: NotifcationQueryParams
  rejectPortalInvite: UseMutateFunction<
    AxiosResponse<any, any> | undefined,
    unknown,
    string,
    unknown
  >
  hasNextPage: boolean | undefined
  unreadNotifications: number
  markAllAsRead: () => void
  processNotification: (id: string) => Promise<void>
}
export const NotificationContext = createContext<INotificationState | null>(
  null
)
const NotificationProvider = ({ children }: { children: JSX.Element }) => {
  const walletAddress = usePrivyHelper().walletAddress
  const { address: contractAddress } = useParams()
  const queryClient = useQueryClient()
  const [notificationCount, setNotificationCount] = useState(0)
  const [lastNotificationTime, setLastNotificationTime] =
    useState(DEFAULT_TIMESTAMP)

  const [searchParams] = useSearchParams()
  const chain = parseInt(searchParams.get('chainId') || '')
  const [selectedPortal, setSelectedPortal] = useState<ISelectedPortal>({
    name: 'All portals',
    address: '',
  })
  const credential = useInvokerCredential(
    walletAddress as string,
    contractAddress as string
  )
  const [queryParams, setQueryParams] = useState<NotifcationQueryParams>({
    limit: 6,
    portalAddress: '' as string,
  })
  const notifyUser = (count: number, data: INotification) => {
    if (count > 0) {
      const n = new Notification(`You've got ${count} new notifications`, {
        body: `${data.message}`,
        icon: data?.portalLogo || Logo,
        // vibrate: [200, 100, 200],
        requireInteraction: true,
      })
      n.onclick = () => {
        window.open(
          `${window.origin}${window.location.pathname}#/${contractAddress}?chainId=${chain}`
        )
      }
    }
  }
  const triggerDesktopNotificaton = (count: number, data: INotification) => {
    if ('Notification' in window) {
      if (Notification.permission === 'granted') {
        notifyUser(count, data)
      } else {
        Notification.requestPermission().then((res) => {
          if (res === 'granted') {
            notifyUser(count, data)
          }
        })
      }
    }
  }
  const handleNotification = async ({
    pageParam = 0,
  }): Promise<{ data: INotification[]; prevOffset: number }> => {
    const data: INotification[] = await getNotification({
      contractAddress: contractAddress as string,
      walletAddress: walletAddress as string,
      editSecret: credential?.editSecret,
      chainId: chain,
      queryParams: {
        ...queryParams,
        offset: pageParam,
      },
    })

    if (data?.length) {
      if (lastNotificationTime === DEFAULT_TIMESTAMP) {
        const count = data.filter((item) => !item.processed)
        if (count.length) {
          triggerDesktopNotificaton(count.length, count[0])
        }
      } else {
        const count = data.filter(
          (item) => new Date(item.timeStamp) > new Date(lastNotificationTime)
        )
        if (count.length > notificationCount) {
          setNotificationCount(count.length)
          triggerDesktopNotificaton(count.length, count[0])
        }
      }
    }
    return { data: data as INotification[], prevOffset: pageParam }
  }

  const {
    data,
    isLoading: isNotificationsLoading,
    isError: isNotificationError,
    isFetching: isFetchingNotification,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [NOTIFICATION_QUERY_KEY, queryParams],
    queryFn: ({ pageParam }) => handleNotification(pageParam as any),
    initialPageParam: 0,
    getNextPageParam: (lastNotifications: any) => {
      return lastNotifications.prevOffset + 6
    },
    // onSuccess: () => {
    //   queryClient.invalidateQueries({
    //     queryKey: [FETCH_TOTAL_UNREAD_NOTIFICATION_KEY],
    //   })
    // },
    refetchInterval: 5000,
  })
  const { mutate: _processNotification } = useMutation({
    mutationFn: (ids: string[]) =>
      processNotificationApi({
        contractAddress: contractAddress as string,
        credentialEditSecret: credential?.editSecret,
        list: ids,
        invoker: walletAddress as string,
        chain,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATION_QUERY_KEY, queryParams],
      })
    },
  })
  const { mutate: _markAllAsRead } = useMutation({
    mutationFn: () =>
      markAllNotificationsAsRead({
        contractAddress: contractAddress as string,
        editSecret: credential?.editSecret,
        chain,
        invoker: walletAddress as string,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATION_QUERY_KEY, queryParams],
      })
    },
  })
  const { mutate: rejectPortalInvite } = useMutation({
    mutationFn: (notificationId: string) =>
      rejectPortalInviteAPI({
        contractAddress: contractAddress as string,
        editSecret: credential?.editSecret,
        chain,
        invoker: walletAddress as string,
        notificationId,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [NOTIFICATION_QUERY_KEY, queryParams],
      })
    },
  })

  const { data: unreadNotifications } = useQuery({
    queryFn: () =>
      getTotalUnreadNotifications({
        contractAddress: contractAddress as string,
        editSecret: credential?.editSecret,
        walletAddress: walletAddress as string,
        chainId: chain,
      }),
    queryKey: [FETCH_TOTAL_UNREAD_NOTIFICATION_KEY],
  })

  const processNotification = async (id: string) => {
    _processNotification([id])
  }
  const markAllAsRead = () => {
    _markAllAsRead()
  }

  const notifications = getNotifications(data)
  if (
    notifications.length &&
    new Date(notifications[0].timeStamp) > new Date(lastNotificationTime)
  ) {
    setLastNotificationTime(notifications[0].timeStamp)
  }
  return (
    <NotificationContext.Provider
      value={{
        notifications,
        isNotificationsLoading,
        isNotificationError,
        unreadNotifications,
        markAllAsRead,
        setQueryParams,
        hasNextPage,
        selectedPortal,
        setSelectedPortal,
        fetchNextPage,
        queryParams,
        isFetchingNotification,
        processNotification,
        rejectPortalInvite,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider

export const getNotifications = (
  data:
    | InfiniteData<{
        data: INotification[]
        prevOffset: number
      }>
    | undefined
) => {
  const _notifications = data?.pages[0]?.data
    ? data?.pages?.reduce((acc: any, page: any) => {
        return [...acc, ...page.data]
      }, [])
    : []
  return _notifications
}
