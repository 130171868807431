/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThreeDTypeExtensions } from '../constants'
import { DocType } from '../pages/PublicPortal/interfaces'
import { FileSource } from '../types/enum/file.enum'
import { INewFile } from '../types/interface/file.interface'
import { FileType as UiFileType } from '@fileverse/ui'

export enum FileType {
  Image = 'Image',
  Video = 'Video',
  Document = 'Document',
  Sheet = 'Sheet',
  PDF = 'PDF',
  DPage = 'DPage',
  Whiteboard = 'Whiteboard',
  DDoc = 'DDoc',
  Audio = 'Audio',
  Other = 'Other',
}

export const SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB']

export const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'svg']
export const VIDEO_EXTENSIONS = ['mp4', 'mov', 'avi', 'mkv']
export const DOCUMENT_EXTENSIONS = ['doc', 'docx', 'txt']
export const SHEET_EXTENSIONS = ['xls', 'xlsx']
export const PDF_EXTENSIONS = ['pdf']
export const AUDIO_EXTENSIONS = [
  'mp3',
  'wav',
  'ogg',
  'aac',
  'aa',
  'm4a',
  'wav',
  'ogg',
]

// export const getPluginExtension = (fileSource: FileSource) => {
//   if (fileSource === FileSource.DPAGE) return 'dpage'
//   if (fileSource === FileSource.COLLAB_DOCUMENT) return 'collab'
//   if (fileSource === FileSource.EXCALIDRAW) return 'excal'
//   if (fileSource === FileSource.NOTES) return 'notes'

//   return ''
// }

export const getFileExtension = (fileName: string): string => {
  if (!fileName) return ''
  const lastDotIndex = fileName.lastIndexOf('.')

  // No file extension found
  if (lastDotIndex === -1) return ''

  const extension = fileName.slice(lastDotIndex + 1)

  // Handle cases like "file.tar.gz"
  if (extension.includes('/')) {
    const parts = extension.split('/')
    return parts[parts.length - 1]
  }

  return extension
}

export const getFileNameWithoutExtension = (fileName: string): string => {
  if (!fileName) return ''
  const lastDotIndex = fileName.lastIndexOf('.')

  // No file extension found
  if (lastDotIndex === -1) return fileName

  return fileName.slice(0, lastDotIndex)
}

export const getFileType = (fileName: string, fileArgs?: any): FileType => {
  const fileExtension = getFileExtension(fileName)

  if (IMAGE_EXTENSIONS.includes(fileExtension)) return FileType.Image
  if (VIDEO_EXTENSIONS.includes(fileExtension)) return FileType.Video
  if (DOCUMENT_EXTENSIONS.includes(fileExtension)) return FileType.Document
  if (SHEET_EXTENSIONS.includes(fileExtension)) return FileType.Sheet
  if (PDF_EXTENSIONS.includes(fileExtension)) return FileType.PDF
  if (AUDIO_EXTENSIONS.includes(fileExtension)) return FileType.Audio
  if (fileArgs?.source === FileSource.DPAGE) return FileType.DPage
  if (fileArgs?.source === FileSource.EXCALIDRAW) return FileType.Whiteboard
  if (fileArgs?.source === FileSource.COLLAB_DOCUMENT) return FileType.DDoc

  return FileType.Other
}

export const formatFileSize = (bytes: number, precision = 2) => {
  const unitIndex = Math.floor(Math.log(bytes) / Math.log(1024))
  const unit = SIZE_UNITS[unitIndex]
  const size = bytes / Math.pow(1024, unitIndex)
  return `${size.toFixed(precision)}${unit}`
}

export const ellipseStringAtMid = (str: string, maxLen = 10) => {
  if (!str || typeof str !== 'string') return ''
  if (str.length <= maxLen) return str

  const leftHalf = str.slice(0, maxLen / 2 - 1)
  const rightHalf = str.slice(-maxLen / 2 + 1)
  return `${leftHalf}...${rightHalf}`
}

export const ellipseFileName = (fileName: string) => {
  const fileExtension = getFileExtension(fileName)
  const fileNameWithoutExtension = fileName.replace(`.${fileExtension}`, '')
  if (!fileExtension) return ellipseStringAtMid(fileNameWithoutExtension)
  return ellipseStringAtMid(fileNameWithoutExtension) + `.${fileExtension}`
}

export const validateFile = (file: File, fileArgs: any) => {
  const { maxUploadSizePerFile = Infinity } = fileArgs
  if (file.size > maxUploadSizePerFile)
    return `Max allowed file size is ${formatFileSize(maxUploadSizePerFile, 0)}`
  return null
}

// Redundant functions
const PLUGIN_FILE_MAP: Record<any, UiFileType> = {
  [FileSource.COLLAB_DOCUMENT]: 'dDoc',
  [FileSource.DPAGE]: 'dPage',
  [FileSource.EXCALIDRAW]: 'whiteboard',
  [FileSource.NOTES]: 'note',
}
// Redundant functions
const getUserFileSystemFileType = (file: INewFile): DocType | UiFileType => {
  const { name } = file.metadata
  const fileExtension = getFileExtension(name)

  if (IMAGE_EXTENSIONS.includes(fileExtension)) return 'media'
  if (VIDEO_EXTENSIONS.includes(fileExtension)) return 'media'
  if (ThreeDTypeExtensions.includes(fileExtension)) return '3d'
  if (DOCUMENT_EXTENSIONS.includes(fileExtension)) return 'doc'
  if (SHEET_EXTENSIONS.includes(fileExtension)) return 'xls'
  if (PDF_EXTENSIONS.includes(fileExtension)) return 'pdf'
  if (AUDIO_EXTENSIONS.includes(fileExtension)) return 'media'

  return 'media'
}

// Redundant functions
export const getFileTypeForCard = (file: INewFile) => {
  if (
    file.metadata.source === FileSource.USER_FILE_SYSTEM ||
    file.metadata.source === FileSource.ARWEAVE
  )
    return getUserFileSystemFileType(file)

  return PLUGIN_FILE_MAP[file.metadata.source]
}
